import { useFormatEventDate, useFormatEventDay } from '../../events';
import { StackEvents } from '../index';
import { StackEventCard } from './StackEventCard';

export interface StackEventsListProps {
  events: StackEvents;
  stackId?: string;
}

// TODO форматирование даты
export function StackEventsList({ events, stackId }: StackEventsListProps) {
  const { formatEventDate } = useFormatEventDate();
  const { formatEventDay } = useFormatEventDay();
  return events.map(({ date, events }) => (
    <div className="flex flex-col gap-3" key={date}>
      <div className="typo-header-small py-2">
        {formatEventDate(date)},{' '}
        <span className="text-tg-hint capitalize">{formatEventDay(date)}</span>
      </div>
      {events.map((event) => (
        <StackEventCard event={event} key={event.id} stackId={stackId} />
      ))}
    </div>
  ));
}
