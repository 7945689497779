import { isSameDay } from 'date-fns';

import { Event, StackEvent } from '../../../shared/api';
import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';
import { fixFullDayEventStart } from './fixFullDayEventStart';
import { getEventInterval } from './getEventInterval';
import { isStackEvent } from './isStackEvent';

export function useFormatEventDateAndTime() {
  const { format } = useLocalisedDateFormat();

  return {
    formatEventDateAndTime: (
      event: Event | StackEvent,
      hideSingleDayDate = false,
    ) => {
      const { end, start } = getEventInterval(
        isStackEvent(event) ? event : fixFullDayEventStart(event),
      );

      if (isSameDay(start, end)) {
        return `${hideSingleDayDate ? '' : format(start, 'dd MMMM yyy, ')}${format(start, 'HH:mm')} -  ${format(end, 'HH:mm')}`;
      }

      return `${format(start, 'dd MMM yyy HH:mm')} - ${format(end, 'dd MMM yyy HH:mm')}`;
    },
  };
}
