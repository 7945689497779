import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { formatDuration, intervalToDuration } from 'date-fns';

import { Event, StackEvent } from '../../../shared/api';
import { openShareDialog } from '../../../shared/lib/openShareDialog';
import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';
import { createViewEventDeepLink } from './createViewEventDeepLink';
import { getEventInterval } from './getEventInterval';
import { isStackEvent } from './isStackEvent';

export function useShareEvent() {
  const { format, userLocale } = useLocalisedDateFormat();
  const { t } = useTranslation();
  const { analytics } = useJitsu();

  const shareEvent = (event: Event | StackEvent) => {
    const eventInterval = getEventInterval(event);

    const viewEventDeepLink = createViewEventDeepLink(
      isStackEvent(event) ? event.token : event.attributes.token,
    );
    const title = isStackEvent(event) ? event.title : event.attributes.title;

    analytics.track('shareEvent', { eventId: event.id });

    openShareDialog(
      [
        t('all:event.share.title'),
        t('all:event.share.text'),
        '',
        `💥 ${title}`,
        `📅 ${t('all:event.share.when', {
          date: format(eventInterval.start, 'd MMMM yyy'),
          time: format(eventInterval.start, 'HH:mm'),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })}`,
        `🕒 ${formatDuration(intervalToDuration(eventInterval), { locale: userLocale })}`,
      ].join('\n'),
      viewEventDeepLink,
    );
  };

  return {
    shareEvent,
  };
}
