import { useTranslation } from 'react-i18next';

import { isToday, isTomorrow } from 'date-fns';

import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';

export function useFormatEventDay() {
  const { t } = useTranslation();
  const { format } = useLocalisedDateFormat();
  const formatEventDay = (date: string) => {
    let day = format(date, 'EEEE');
    if (isToday(date)) {
      day = t('all:common.day.today');
    }
    if (isTomorrow(date)) {
      day = t('all:common.day.tomorrow');
    }
    return day;
  };
  return { formatEventDay };
}
